
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

interface Plan {
  price: string;
  units: string;
}

interface Employee {
  title: string;
  description: string;
  tasks: string[];
  status: string;
  template_id: number;
  category: string;
  available_in: string[];
  plans: {
    [key: string]: Plan;
  };
  channel: string;
  img_path: string;
}

interface APIResponse {
  categories: string[];
  virtual_employees: Employee[];
}

export const VirtualStaff = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const [selectedPlans, setSelectedPlans] = useState<{ [key: string]: string }>(
    {}
  );

  const { data, isLoading, isError } = useQuery<APIResponse>(
    [queries.employeelist],
    () => getRequest("agents/hiring-list"),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const employeelist = data?.virtual_employees || [];

  useEffect(() => {
    if (employeelist.length > 0) {
      const initialSelectedPlans: { [key: string]: string } = {};
      employeelist.forEach((obj) => {
        if (obj.available_in.length > 0) {
          initialSelectedPlans[obj.template_id] = obj.available_in[0];
        }
      });
      setSelectedPlans(initialSelectedPlans);
    }
  }, [employeelist]);
  
  if (isLoading)
    return <div className="text-center text-white">Loading...</div>;
  if (isError)
    return <div className="text-center text-white">Error loading data</div>;

  return (
    <div className="w-full h-full p-4 text-white">
      <ScrollArea className="h-[calc(100vh-85px)]">
        <div className="text-center w-full px-4">
          <h1 className="text-white text-2xl pt-20 mb-20">
            Who would you like to
            <span className="text-4xl p-2 font-black text-logo font-custom letter-spacing: ">
              hire
            </span>
            today?
          </h1>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 pt-18">
            {employeelist.map((obj: Employee) => (
              <div
                key={obj.template_id}
                className="bg-primary border border-header-border p-6 rounded-lg text-white hover:bg-[#333333] cursor-pointer flex flex-col h-[450px] relative"
              >
                <div className="flex items-center mb-4">
                  <div className="h-10 w-10 flex items-center justify-center bg-header-border rounded-lg mr-4">
                    <span className="text-2xl font-bold">+</span>
                  </div>
                  <h2 className="text-xl font-bold">{obj.title}</h2>
                </div>

                <p className="text-left text-perl mb-4">{obj.description}</p>

                <ul className="list-none text-left mb-4">
                  {obj.tasks.map((task, index) => (
                    <li className="flex items-center mb-2" key={index}>
                      <svg
                        className="h-4 w-4 mr-2 text-green-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      <span>{task}</span>
                    </li>
                  ))}
                </ul>

                <div className="mt-auto">
                  <div className="flex items-center justify-between mb-4">
                    <div className="relative flex-grow">
                      <select
                        className="w-full bg-primary border border-header-border text-white px-3 py-2 pr-8 rounded-lg text-sm appearance-none"
                        value={selectedPlans[obj.template_id] || ""}
                        onChange={(e) =>
                          setSelectedPlans({
                            ...selectedPlans,
                            [obj.template_id]: e.target.value,
                          })
                        }
                      >
                        {obj.available_in.map((plan: string) => (
                          <option key={plan} value={plan}>
                            {plan}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="ml-4 text-right flex-shrink-0 w-full"
                      style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                      }}
                    >
                      {selectedPlans[obj.template_id] &&
                        obj.plans[selectedPlans[obj.template_id]] && (
                          <div className="text-lg">
                            {" "}
                            {/* Adjust font size */}{" "}
                            <span className="font-black text-yellow-400">
                              {" "}
                              {
                                obj.plans[
                                  selectedPlans[obj.template_id]
                                ].price.split("/")[0]
                              }{" "}
                            </span>{" "}
                            <span className="font-normal text-white">/</span>
                            <span className="font-normal text-white">
                              {" "}
                              {
                                obj.plans[
                                  selectedPlans[obj.template_id]
                                ].price.split("/")[1]
                              }{" "}
                            </span>
                            <div className="font-normal text-white">
                              {" "}
                              {
                                obj.plans[selectedPlans[obj.template_id]].units
                              }{" "}
                            </div>{" "}
                          </div>
                        )}
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      navigate(
                        `/agents/create?id=${obj.template_id}`
                        // &plan=${selectedPlans[obj.template_id]}`
                      )
                    }
                    className="bg-[#00A86B] text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-[#009960] transition-colors duration-200 w-full"
                    disabled={!selectedPlans[obj.template_id]}
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

